<template>
    <div :class="rootClasses">

        <div v-for="(block, key) in data.blocks" :key="key" class="content" :class="{'margin-b' : data.blocks.length > 1 && key != data.blocks.length - 1}">
            <div v-if="data.blocks.length > 1" class="content-background-image" :class="{ 'img-right': block.row_reverse }">
                <img v-if="!block.row_reverse"
                    src="https://recht-einfach.schule/wp-content/uploads/2021/03/background01-1.png" alt="ooops">
                <img v-else
                    src="https://recht-einfach.schule/wp-content/uploads/2021/02/%D0%92%D1%8B%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%B8%D0%B5-2-2.png"
                    alt="ooops">
            </div>
            <div class="container">
                <div class="row main-row" :class="{ reverse: block.row_reverse }">
                    <div class="col-12 col-lg-6">
                        <div v-if="block.image" class="content-image">
                            <img :src="block.image" :alt="block.imgAltText">
                        </div>
                        <div v-else class="content-text">
                            <span v-html="block.secondText"></span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="content-info">
                            <div class="content-info-title">{{ block.title }}</div>
                            <div class="content-info-text" v-html="block.text"></div>
                            <div v-if="block.bottomButtons" class="content-info-buttons">
                                <a v-for="(button, key) in block.bottomButtons" :key="key" :href="button.buttonLink">
                                    {{ button.buttonText }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextWithImage',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        rootClasses() {
            let result = {
                ['cs-text-with-image']: true,
                ['theme-block']: true
            }

            if (this.data?.hasBackground)
                result = {
                    ...result,
                    ...{
                        ['has-background']: true,
                        ['theme-bg-colored']: true
                    }
                }

            return result
        }
    },
    methods: {
        rowReverse(data) {
            if (data === true) {
                return 'flex-row-reverse'
            } else {
                return 'flex-row'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/main.scss';

::v-deep {
    .content {
        position: relative;

        .container {
            position: relative;
            z-index: 2;
        }

        .main-row {
            align-items: center;
            row-gap: 30px;
        }

        .reverse {
            flex-direction: row-reverse;
        }

        &-image {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-info {
            &-title {
                font-family: 'Montserrat';
                font-weight: bold;
                font-size: 30px;
                color: $primary_text_color;
                margin-bottom: 0px;
                word-wrap: break-word;

                @media (max-width: 500px) {
                    font-size: 25px;
                }
            }

            &-text {
                font-family: 'Montserrat';
                font-weight: normal;
                font-size: 16px;
                color: #000;
                margin-bottom: 0px;
                margin-top: 15px;

                ::v-deep {
                    * {
                        font-family: 'Montserrat';
                        font-weight: normal;
                        font-size: 16px;
                        color: #000;
                        margin-bottom: 0px;
                        margin-top: 15px;
                    }
                }
            }

            &-buttons {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 15px;
                margin-top: 30px;

                a {
                    @include theme-button;
                    font-family: 'Montserrat';
                    margin-top: 15px;

                    @media (max-width: 410px) {
                        width: 100%;
                    }
                }
            }
        }

        &-text {
            display: flex;

            span {
                word-wrap: break-word;
                margin-bottom: 0px;
                font-family: 'Saira Stencil One';
                font-weight: normal;
                font-size: 50px;
                color: #002164;

                ::v-deep {
                    * {
                        word-wrap: break-word;
                        margin-bottom: 0px;
                        font-family: 'Saira Stencil One';
                        font-weight: normal;
                        font-size: 50px;
                        color: #002164;
                    }
                }

                @media (max-width: 780px) {
                    max-width: 100%;
                }
            }
        }

        &-background-image {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                object-fit: contain;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }

        .img-right {
            display: flex;
            justify-content: flex-end;
        }
    }

    .margin-b{
        margin-bottom: 100px;
    }
}</style>
